export default {
    SET_REQUESTS: 'SET_REQUESTS',
    APPEND_REQUESTS: 'APPEND_REQUESTS',

    SET_ANCILLARY_SERVICES: 'SET_ANCILLARY_SERVICES',
    UNSET_ANCILLARY_SERVICES: 'UNSET_ANCILLARY_SERVICES',

    SET_AST_INSPECTOR_ANCILLARY_SERVICES: 'SET_AST_INSPECTOR_ANCILLARY_SERVICES',
    UNSET_AST_INSPECTOR_ANCILLARY_SERVICES: 'UNSET_AST_INSPECTOR_ANCILLARY_SERVICES',

    SET_SELECTED_REQUEST: 'SET_SELECTED_REQUEST',
    CLEAR_SELECTED_REQUEST: 'CLEAR_SELECTED_REQUEST',
    UPDATE_REQUEST: 'UPDATE_REQUEST', 
    
    REMOVE_APPROVED_REQUEST_FROM_LIST: 'REMOVE_APPROVED_REQUEST_FROM_LIST',
    REMOVE_DECLINED_REQUEST_FROM_LIST: 'REMOVE_DECLINED_REQUEST_FROM_LIST',

    UPDATE_ORDER_STATUS_COMPLETED: 'UPDATE_ORDER_STATUS_COMPLETED',
    UPDATE_ORDER_STATUS_CANCELLED: 'UPDATE_ORDER_STATUS_CANCELLED',

    TOGGLE_REQUEST_APPROVED: 'TOGGLE_REQUEST_APPROVED',
    TOGGLE_REQUEST_REMOVED: 'TOGGLE_REQUEST_REMOVED',

    TOGGLE_UPDATE_APPROVED: 'TOGGLE_UPDATE_APPROVED',
    
    TOGGLE_LOADING: 'TOGGLE_LOADING',
    TOGGLE_IS_LAST_PAGE: 'TOGGLE_IS_LAST_PAGE',
    TOGGLE_IS_PAGINATING: 'TOGGLE_IS_PAGINATING',
    TOGGLE_IS_PROCESSING: 'TOGGLE_IS_PROCESSING',

    SET_MANAGER_INSPECTOR_PENDING_BADGE_COUNT:  'SET_MANAGER_INSPECTOR_PENDING_BADGE_COUNT'
}