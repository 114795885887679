import requestActionTypes from '../actionTypes'
import * as managerActionTypes from '../../../../redux/actions/managers'

const initialState = {
    isLoading: false,                               // initial loading state
    isProcessing: false,                            // initial loading state
    isPaginating: false,                            // pagination loader state
    isLastPage: false,                              // last page boolean state
    requests: [],                                   // data
    ancillaryServices: [],                          // Ancillary Services data
    ancillaryServicesFound: false,                  // Ancillary Services data flag
    astInspectorAncillaryServices: [],              // Ast Inspector Ancillary Services data
    astInspectorAncillaryServicesFound: false,      // Ast Inspector Ancillary Services data flag
    currentRequest: null,                           // selected data
    requestApproved: false,                         // To show toast when request approve success
    requestDeclined: false,                         // To show toast when request approve failure
    updateApproved: false,                          // To show toast when request update request success
    managerInspectorPendingBadgeCounts: 0          // pending inspection count for manager
}

export default (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case requestActionTypes.TOGGLE_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case requestActionTypes.TOGGLE_IS_PROCESSING:
            return {
                ...state,
                isProcessing: payload
            }
        case requestActionTypes.TOGGLE_IS_PAGINATING:
            return {
                ...state,
                isPaginating: payload
            }
        case requestActionTypes.TOGGLE_IS_LAST_PAGE:
            return {
                ...state,
                isLastPage: payload
            }
        case requestActionTypes.SET_ANCILLARY_SERVICES:
            return {
                ...state,
                ancillaryServices: payload,
                ancillaryServicesFound: true
            }
        case requestActionTypes.UNSET_ANCILLARY_SERVICES:
            return {
                ...state,
                ancillaryServices: [],
                ancillaryServicesFound: false
            }
        case requestActionTypes.SET_AST_INSPECTOR_ANCILLARY_SERVICES:
            return {
                ...state,
                astInspectorAncillaryServices: payload,
                astInspectorAncillaryServicesFound: true
            }
        case requestActionTypes.UNSET_AST_INSPECTOR_ANCILLARY_SERVICES:
            return {
                ...state,
                astInspectorAncillaryServices: [],
                astInspectorAncillaryServicesFound: false
            }
        case requestActionTypes.SET_REQUESTS:
            return {
                ...state,
                requests: payload
            }
        case requestActionTypes.APPEND_REQUESTS:
            return {
                ...state,
                requests: [...state.requests, ...payload]
            }
        case requestActionTypes.SET_SELECTED_REQUEST:
            return {
                ...state,
                currentRequest: payload
            }
        case requestActionTypes.CLEAR_SELECTED_REQUEST:
            return {
                ...state,
                currentRequest: null
            }
        case requestActionTypes.REMOVE_APPROVED_REQUEST_FROM_LIST: {
            const removableOrderId = action.payload
            const existingRequests = [...state.requests]
            const filteredData = existingRequests.filter(request => request.orderMasterId !== removableOrderId)
            return {
                ...state,
                requests: filteredData,
                requestApproved: existingRequests.length > filteredData.length
            }
        }
        case requestActionTypes.REMOVE_DECLINED_REQUEST_FROM_LIST:
            const removableOrderId = action.payload
            const existingRequests = [...state.requests]
            const filteredData = existingRequests.filter(request => request.orderMasterId !== removableOrderId)
            return {
                ...state,
                requests: filteredData,
                requestDeclined: existingRequests.length > filteredData.length
            }
        case requestActionTypes.TOGGLE_REQUEST_APPROVED:
            return {
                ...state,
                requestApproved: action.payload
            }
        case requestActionTypes.TOGGLE_REQUEST_REMOVED:
            return {
                ...state,
                requestDeclined: action.payload
            }
        case requestActionTypes.TOGGLE_UPDATE_APPROVED:
            return {
                ...state,
                updateApproved: action.payload
            }
        case requestActionTypes.UPDATE_REQUEST:
            {
                const existingRequests = [...state.requests]
                const updatableIndex = existingRequests.findIndex(request => parseInt(request.orderMasterId) === parseInt(action.payload.orderMasterId))
                if (updatableIndex > -1) {
                    existingRequests[updatableIndex] = action.payload
                }
                return {
                    ...state,
                    requests: existingRequests,
                    updateApproved: updatableIndex > -1
                }
            }
        case managerActionTypes.SELECT_MANAGER:
        case 'LOGOUT':
            return initialState
        case requestActionTypes.SET_MANAGER_INSPECTOR_PENDING_BADGE_COUNT:
            return {
                ...state,
                managerInspectorPendingBadgeCounts: payload
            }
        default:
            return state
    }
}