// ** Initial State
const initialState = {
  isNotificationDropDownOpen: false,
  unReadNotificationCount: 0,

  isLoading: false,
  currentPage: 0,
  isLastPage: false,

  notifications: [],

  selectedNotification: {},

  newInspectionRequest: {
    isModalOpen: false
  }
}

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'START_NOTIFICATION_LOADING': {
      return {
        ...state,
        isLoading: true
      }
    }

    case 'STOP_NOTIFICATION_LOADING': {
      return {
        ...state,
        isLoading: false
      }
    }

    case 'SET_NOTIFICATION_DATA': {
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload]
      }
    }

    case 'CLEAR_NOTIFICATION_DATA': {
      return {
        ...state,
        notifications: [],
        currentPage: 0,
        isLastPage: false,
        isLoading: false,
        unReadNotificationCount: 0
      }
    }

    case 'SET_NOTIFICATION_CURRENT_PAGE': {
      return {
        ...state,
        currentPage: action.payload
      }
    }

    case 'SET_NOTIFICATION_IS_PAGE': {
      return {
        ...state,
        isLastPage: action.payload
      }
    }

    case 'UPDATE_UNREAD_NOTIFICATION_COUNT': {
      return {
        ...state,
        unReadNotificationCount: action.payload
      }
    }

    case 'SET_SELECTED_NOTIFICATION_DATA': {
      return {
        ...state,
        selectedNotification: action.payload
      }
    }

    case 'OPEN_NEW_INSPECTION_REQUEST_MODAL': {
      return {
        ...state,
        newInspectionRequest: {
          ...state.newInspectionRequest,
          isModalOpen: true
        }
      }
    }

    case 'CLOSE_NEW_INSPECTION_REQUEST_MODAL': {
      return {
        ...state,
        newInspectionRequest: {
          ...state.newInspectionRequest,
          isModalOpen: false
        }
      }
    }

    case 'TOGGLE_NOTIFICATION_DROPDOWN': {
      return {
        ...state,
        isNotificationDropDownOpen: !state.isNotificationDropDownOpen
      }
    }

    case 'MARK_AS_READ_NOTIFICATION': {
      const { notificationId } = action.payload

      const updatedNotifications = [...state.notifications].map(
        (notification) => {
          if (parseInt(notificationId) === parseInt(notification.id)) {
            return { ...notification, is_read: 1 }
          }

          return { ...notification }
        }
      )

      return { ...state, notifications: updatedNotifications }
    }

    case 'MARK_AS_READ_ALL_NOTIFICATIONS': {
      const updatedNotifications = [...state.notifications].map((notification) => {
          return { ...notification, is_read: 1 }
      })

      return { ...state, notifications: updatedNotifications }
    }

    default:
      return state
  }
}

export default notificationReducer
