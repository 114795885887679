import axios from '@src/axios'

// ** Update Un Read Notification Count
export const updateNotificationCount = (count = 0) => {
  return async (dispatch) => {
    dispatch({
      type: 'UPDATE_UNREAD_NOTIFICATION_COUNT',
      payload: count
    })
  }
}

// ** Update Un Read Notification Count
export const toggleNotificationDropDown = () => {
  return async (dispatch) => {
    dispatch({ type: 'TOGGLE_NOTIFICATION_DROPDOWN' })
  }
}

// ** Start Notification Loading
export const startNotificationLoading = () => {
  return async (dispatch) => {
    dispatch({ type: 'START_NOTIFICATION_LOADING' })
  }
}

// ** Stop Notification Loading
export const stopNotificationLoading = () => {
  return async (dispatch) => {
    dispatch({ type: 'STOP_NOTIFICATION_LOADING' })
  }
}

// ** Set Notification Data
export const setNotificationData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_NOTIFICATION_DATA', payload })
  }
}

// ** Clear Notification Data
export const clearNotificationData = () => {
  return async (dispatch) => {
    dispatch({ type: 'CLEAR_NOTIFICATION_DATA' })
  }
}

// ** Set Notification Current Page
export const setNotificationCurrentPage = (page = 0) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_NOTIFICATION_CURRENT_PAGE', payload: page })
  }
}

// ** Set Notification Is Last Page
export const setNotificationIsLastPage = (isLastPage) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_NOTIFICATION_IS_PAGE', payload: isLastPage })
  }
}

// ** Handle Get Notifications
export const getNotifications = (params) => {
  return async (dispatch) => {
    const { data: response } = await axios.get('push-notification', { params })

    const {
      payload: { data, meta }
    } = response

    dispatch(setNotificationData(data))
    dispatch(setNotificationCurrentPage(meta.current_page))
    dispatch(
      setNotificationIsLastPage(
        parseInt(meta.current_page) >= parseInt(meta.last_page)
      )
    )

    return response
  }
}

// ** Handle Mark as Read Notification
export const notificationMarkAsRead = (notificationId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `push-notification/mark-as-read/${notificationId}`
      )

      dispatch({
        type: 'MARK_AS_READ_NOTIFICATION',
        payload: {
          notificationId
        }
      })

      dispatch(
        updateNotificationCount(data?.payload?.unread_notification_count)
      )

      return data
    } catch (err) {
      throw new Error(err)
    }
  }
}

// ** Handle Mark as Read Notification
export const allNotificationsMarkAsRead = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post('/push-notification/manager/mark-as-read')

      dispatch({
        type: 'MARK_AS_READ_ALL_NOTIFICATIONS'
      })

      dispatch(
        updateNotificationCount(data?.payload?.unread_notification_count)
      )

      return data
    } catch (err) {
      throw new Error(err)
    }
  }
}

// ** Set Selected Notification Data
export const setSelectedNotificationData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_SELECTED_NOTIFICATION_DATA', payload })
  }
}

// ** Clear Selected Notification Data
export const clearSelectedNotificationData = () => {
  return async (dispatch) => {
    dispatch(setSelectedNotificationData(null))
  }
}

// ** Open New Inspection Modal
export const openNewInspectionRequestModal = () => {
  return async (dispatch) => {
    dispatch({ type: 'OPEN_NEW_INSPECTION_REQUEST_MODAL' })
  }
}

// ** Close New Inspection Modal
export const closeNewInspectionRequestModal = () => {
  return async (dispatch) => {
    dispatch({ type: 'CLOSE_NEW_INSPECTION_REQUEST_MODAL' })
  }
}
