import * as inspectorActions from '../../actions/inspectors'

const initialState = {
  isLoading: false,
  data: [],
  datatable: {
    data: [],
    total: 1
  },
  zipCode: {
    datatable: {
      data: [],
      total: 1
    }
  },
  daySchedules: {
    datatable: {
      data: [],
      total: 1
    }
  },
  ast_inspectors: []
}

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case inspectorActions.SET_INSPECTOR_DATA:
      return {
        ...state,
        data: payload
      }
    case inspectorActions.UNSET_INSPECTOR_DATA:
      return {
        ...state,
        data: []
      }
    case inspectorActions.SET_AST_INSPECTOR_DATA:
      return {
        ...state,
        ast_inspectors: payload
      }
    case inspectorActions.TOGGLE_LOADER:
      return {
        ...state,
        isLoading: payload
      }
    case inspectorActions.GET_INSPECTOR_DATATABLE_DATA:
      return {
        ...state,
        datatable: {
          ...state.datatable,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      }
      case inspectorActions.GET_INSPECTOR_ZIPCODE_DATATABLE_DATA:
        return {
          ...state,
          zipCode: {
            ...state.zipCode.datatable,
            datatable: {
              data: action.data,
              total: action.totalPages,
              params: action.params
            }
          }
        }
        case inspectorActions.GET_INSPECTOR_DAY_SCHEDULE_DATATABLE_DATA:
          return {
            ...state,
            daySchedules: {
              ...state.daySchedules.datatable,
              datatable: {
                data: payload.data,
                total: payload.totalPages,
                params: payload.params
              }
            }
          }
    case 'LOGOUT':
      return initialState
    default:
      return state
  }
}
