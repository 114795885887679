import areaActionTypes from '../actionTypes'

const initialState = {
  datatable: {
    data: [],
    total: 1
  },
  zipcodes: {
    datatable: {
      data: [],
      total: 1
    }
  }
}

export default (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case areaActionTypes.GET_DATATABLE_DATA:
      return {
        ...state,
        datatable: {
          data: payload.data,
          total: payload.totalPages,
          params: payload.params
        }
      }
    case areaActionTypes.GET_AREA_ZIPCODE_DATATABLE_DATA:
      return {
        ...state,
        zipcodes: {
          // ...state.zipcodes,
          datatable: {
            data: payload.data,
            total: payload.totalPages,
            params: payload.params
          }
        }
      }
    default:
      return state
  }
}