import moment from 'moment'

class InspectorModel {
    constructor(props) {
        const {
            user_master_id,     // 94,
            first_name,         // "Prakash",
            last_name,          // "Silversky",
            email,              // "prakash.silversky@gmail.com",
            phone,              // "+918160765985",
            created_date,       // "03/23/2023"
            avtar
        } = props

        this.user_master_id = user_master_id
        this.first_name = first_name
        this.last_name = last_name
        this.email = email
        this.phone = phone
        this.avtar = avtar
        this.created_date = created_date
    }
}

class AvailabilityModel {
    constructor(props) {
        const {
            availability_date_time,
            end_date_time,
            availability_status,
            inspector_availability_master_id,
            order_master_id,
            additional_detail,
            inspection_type,
            user_master_id,
            inspector
        } = props

        this.availability_date_time = availability_date_time
        this.availability_date = moment(availability_date_time).format("YYYY-MM-DD")
        this.availability_time = moment(availability_date_time).format('HH:mm')
        this.availability_day = moment(availability_date_time).format('dddd')?.toLocaleLowerCase() // sunday

        this.end_date_time = end_date_time
        this.end_date = moment(end_date_time).format("YYYY-MM-DD")
        this.end_time = moment(end_date_time).format('HH:mm')
        this.end_day = moment(end_date_time).format('dddd')?.toLocaleLowerCase() // sunday

        this.additional_detail = additional_detail
        this.availability_status = availability_status

        this.inspector_availability_master_id = inspector_availability_master_id
        this.user_master_id = user_master_id
        this.order_master_id = order_master_id

        this.inspection_type = inspection_type

        this.inspector = new InspectorModel(inspector)
    }
}

export default AvailabilityModel
