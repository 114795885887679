import constants from "../../../constants"

class AvailabilityModel {
    constructor(props) {
        const {
            availability_date, // ": "08/06/2022",
            availability_time, // ": "06:00 PM",
            availability_day, // ": "Saturday",
            end_date, // ": "08/06/2022",
            end_time, // ": "07:30 PM",
            end_day, // ": "Saturday",
            inspector_user_master_id, // ": "94",
            inspector_first_name, // ": "Prakash",
            inspector_last_name, // ": "Silversky",
            inspector_avtar, // ": "",
            agent_email, // ": "",
            agent_name, // ": "",
            agent_phone, // ": "",
            buyer_email, // ": "karan.silversky@gmail.com",
            buyer_name, // ": "karan",
            buyer_phone, // ": "1234567890",
            city, // ": "ahmedabad",
            inspector_availability_master_id, // ": "29644",
            order_master_id, // ": "17366",
            order_status, // ": "cancel",
            state, // ": "GJ",
            zipcode, // ": "380015",
            property_address, // ": "SST",
            quote_price, // ": "10.00",
            is_agreement_signed, // ": "0",
            order_modified_date, // ": "1645194017",
            payment_status, // ": "pending",
            notes, // ": "testing",
            cardpoint_payment_url, // ": "https://hdmkhomeins.securepayments.cardpointe.com/pay?total=10&cf_hidden_order_master_id=MTczNjY=&cf_Property_Address=SST",
            sign_page_url, // ": "https://api-sandbox.hdmk.net/Customer/Signature?order_master_id=MTczNjY=",
            actual_amount_paid, // ": "0",
            base_service_charge, // ": "10",
            report_published_at, // ": null,
            AncillaryServices // ": [
        } = props

        this.availability_date = availability_date
        this.availability_time = availability_time
        this.availability_day = availability_day
        this.end_time = end_time
        this.end_date = end_date
        this.end_day = end_day
        this.availability_status = constants.available // order_status
        this.inspector_availability_master_id = inspector_availability_master_id
        this.order_master_id = order_master_id
        this.additional_detail = ""
        this.avtar = inspector_avtar
        this.first_name = inspector_first_name
        this.user_master_id = inspector_user_master_id
    }
}

export default AvailabilityModel
