import moment from 'moment'

export class AncillaryService {
    constructor(props) {
        const {
            service_name,
            service_price_key,
            service_amount
        } = props
        this.service_name = service_name
        this.service_price_key = service_price_key
        this.service_amount = service_amount
    }
}

export class Service {
    constructor(props) {
        const {
            service_name,
            service_price_key,
            is_selected,
            service_short_name
        } = props

        this.service_name = service_name
        this.service_price_key = service_price_key
        this.is_selected = is_selected
        this.service_short_name = service_short_name
    }
}

export class CalendarInspectionAvailability {
    constructor(props) {
        const {
            availability_date_time, //: "2023-05-31 10:00:00",
            end_date_time, //: "2023-05-31 12:30:00",
            additional_detail, //: "",
            user_master_id, //: 2302,
            inspector_availability_master_id, //: 30119,
            type //: "available"
        } = props

        this.availability_date_time = availability_date_time
        this.availability_date = moment(availability_date_time).format("YYYY-MM-DD")
        this.availability_time = moment(availability_date_time).format('HH:mm')
        this.availability_day = moment(availability_date_time).format('dddd')?.toLocaleLowerCase() // sunday

        this.end_date_time = end_date_time
        this.end_date = moment(end_date_time).format("YYYY-MM-DD")
        this.end_time = moment(end_date_time).format('HH:mm')
        this.end_day = moment(end_date_time).format('dddd')?.toLocaleLowerCase() // sunday

        this.additional_detail = additional_detail
        this.user_master_id = user_master_id
        this.inspector_availability_master_id = inspector_availability_master_id
        this.type = type
    }
}

export class CalendarInspectionCustomer {
    constructor(props) {
        const {
            user_master_id, //: 74,
            first_name, //: "test",
            last_name, //: "user",
            email, //: "divyaraj.silversky@gmail.com",
            phone, //: "1234567890",
            avtar, //: "https://hdmk14330713c64b4207bd79e341e57f759b172155-dev.s3.us-west-2.amazonaws.com//",
            created_date //: "2023-03-23 06:52:09"
        } = props

        this.user_master_id = user_master_id
        this.first_name = first_name
        this.last_name = last_name
        this.email = email
        this.phone = phone
        this.avtar = avtar
        this.created_date = created_date
    }
}

export class CalendarInspectionInspector {
    constructor(props) {
        const {
            user_master_id, //: 2302,
            first_name, //: "Sagar",
            last_name, //: "silversky",
            email, //: "sagar.silversky+i@gmail.com",
            phone, //: "8401777870",
            avtar, //: "https://hdmk14330713c64b4207bd79e341e57f759b172155-dev.s3.us-west-2.amazonaws.com//",
            created_date //: "2023-03-23 06:52:09"
        } = props

        this.user_master_id = user_master_id
        this.first_name = first_name
        this.last_name = last_name
        this.email = email
        this.phone = phone
        this.avtar = avtar
        this.created_date = created_date

    }
}

export class Inspector {
    constructor(props) {
        const {
            user_master_id,
            first_name,
            last_name,
            email,
            phone,
            avtar,
            created_date
        } = props

        this.user_master_id = user_master_id
        this.first_name = first_name
        this.last_name = last_name
        this.email = email
        this.phone = phone
        this.avtar = avtar
        this.created_date = created_date
    }

}

export class InspectorServices {
    constructor(props) {
        const {
            inspector_id,
            order_detail_master_id,
            inspector_availability_master_id,
            inspector_type,
            inspector,
            ancillary_services
        } = props
        this.inspector_id = inspector_id
        this.order_detail_master_id = order_detail_master_id
        this.inspector_availability_master_id = inspector_availability_master_id
        this.inspector_type = inspector_type
        this.inspector = new Inspector(inspector)
        this.ancillary_services = Array.isArray(ancillary_services) && ancillary_services.length ? ancillary_services.map(ancillaryService => new Service(ancillaryService)) : []
    }
}

class Inspection {
    constructor(props) {
        const {

            availability_date_time, //: "2023-06-11 11:00:00",
            availability_date, //: "2023-06-11",
            availability_time, //: "11:00",
            availability_day, //: "sunday",
            end_date_time, //: "2023-06-11 13:30:00",
            end_date, //: "2023-06-11",
            end_time, //: "13:30",
            end_day, //: "sunday",
            additional_detail, //: null,


            availability_status,
            user_master_id,
            inspector_first_name,
            inspector_last_name,
            inspector_avtar,
            agent_email,
            agent_name,
            agent_phone,
            list_agent_email,
            list_agent_name,
            list_agent_phone,
            buyer_email,
            buyer_name,
            buyer_phone,
            city,
            inspector_availability_master_id,
            order_master_id,
            order_status,
            state,
            zipcode,
            property_address,
            quote_price,
            is_agreement_signed,
            order_modified_date,
            payment_status,
            notes,
            cardpoint_payment_url,
            sign_page_url,
            actual_amount_paid,
            base_service_charge,
            report_published_at,
            inspection_type,
            is_editable,
            ancillary_services: AncillaryServices,
            inspector_user_master_id,
            inspectors_services,
            customer,
            availability,
            inspector,
            inspected_items
        } = props

        this.availability_date_time = availability_date_time
        this.availability_date = availability_date
        this.availability_time = availability_time
        this.availability_day = availability_day

        this.end_date_time = end_date_time
        this.end_date = end_date
        this.end_time = end_time
        this.end_day = end_day

        this.inspector_first_name = inspector_first_name
        this.inspector_last_name = inspector_last_name
        this.inspector_avtar = inspector_avtar
        this.agent_email = agent_email
        this.agent_name = agent_name
        this.list_agent_phone = list_agent_phone
        this.list_agent_email = list_agent_email
        this.list_agent_name = list_agent_name
        this.agent_phone = agent_phone
        this.buyer_email = buyer_email
        this.buyer_name = buyer_name
        this.buyer_phone = buyer_phone
        this.city = city
        this.inspector_availability_master_id = !!availability ? availability?.inspector_availability_master_id : inspector_availability_master_id
        this.order_master_id = order_master_id
        this.order_status = order_status
        this.state = state
        this.zipcode = zipcode
        this.property_address = property_address
        this.quote_price = quote_price
        this.is_agreement_signed = is_agreement_signed
        this.order_modified_date = order_modified_date
        this.payment_status = payment_status
        this.notes = notes
        this.cardpoint_payment_url = cardpoint_payment_url
        this.sign_page_url = sign_page_url
        this.actual_amount_paid = actual_amount_paid
        this.base_service_charge = base_service_charge
        this.report_published_at = report_published_at

        this.is_editable = is_editable ?? true

        this.inspection_type = inspection_type
        this.inspected_items = inspected_items
        this.is_inspection_type_is_reinspection = inspection_type === 'reinspection'
        this.is_inspection_type_is_standalone = inspection_type === 'standalone'

        this.availability = !!availability ? new CalendarInspectionAvailability(availability) : {}
        this.inspector = !!inspector ? new CalendarInspectionInspector(inspector) : {}
        this.customer = !!customer ? new CalendarInspectionCustomer(customer) : {}

        this.AncillaryServices = Array.isArray(AncillaryServices) && AncillaryServices.length ? AncillaryServices.map(ancillaryService => new AncillaryService(ancillaryService)) : []

        this.inspector_user_master_id = inspector_user_master_id

        this.inspectors_services = Array.isArray(inspectors_services) && inspectors_services.length ? inspectors_services.map(inspectorServices => new InspectorServices(inspectorServices)) : []
    }
}

export default Inspection
